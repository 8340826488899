import { COLORS } from '~/data/ui/colors';
import { FONT_SIZES } from '~/data/ui/fontSizes.data';
import theme from '~/theme';
import { styled } from '@mui/material/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { scrollBar, ArrowsStyle } from '~/styles/useStyleGlobal';

const classes = ({ isSingleBanner }: { isSingleBanner: boolean }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '50px',
    width: '100%',
    background: COLORS.gray.light,
    alignItems: 'center',
    padding: '40px 24px',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      padding: isSingleBanner ? '79px 24px' : '64px 24px',
    },
  },
  title: {
    display: 'flex',
    lineHeight: 'normal',
    gap: '5px',
    fontSize: FONT_SIZES['xl'],
    [theme.breakpoints.up('md')]: {
      fontSize: FONT_SIZES['xl_title'],
    },
  },
});

export const backgroundCustomStyle = {
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
};

export const carouselContainer = {
  width: '100%',
  height: '100%',
  borderRadius: '8px',

  '&:hover': {
    ...ArrowsStyle,
  },
};

export const StyledSwiperSlide = styled(SwiperSlide)(() => ({
  width: '100%',
  height: '216px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '20px',
  [theme.breakpoints.up('md')]: {
    webkitTapHightlightColor: 'transparent',
    webkitTouchCallout: 'none',
    webkitUserSelect: 'none',
    khtmlUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
}));

export const StyledSwiper = styled(Swiper, {
  shouldForwardProp: (prop) =>
    prop !== 'noImages' && prop !== 'isNavigationDots',
})<{ noImages: boolean; isNavigationDots: boolean }>(
  ({ noImages = true, isNavigationDots = false }) => ({
    width: '100%',
    borderRadius: '8px',

    '&.swiper': {
      overflow: 'visible',
      maxWidth: '1061px',
      [theme.breakpoints.up('md')]: {
        paddingInline: '50px',
      },
    },

    '& .swiper-button-prev, .swiper-button-next': {
      display: 'none',
    },
    '&:hover': {
      ...ArrowsStyle,
      '& .swiper-pagination-bullets': {
        [theme.breakpoints.down('md')]: {
          marginBottom: 'none',
        },
      },
    },
    // ...ArrowsStyle,

    '& .swiper-pagination': {
      display: !isNavigationDots ? 'none' : 'block',
    },
    '& .swiper-pagination-bullet-active': {
      boxSizing: 'border-box',
      background: COLORS.yellow.main,
      border: `1px solid ${COLORS.black.main}`,
    },
    '& .swiper-pagination-bullets.swiper-pagination-horizontal': {
      bottom: 'clamp(-40px, calc(30px - ((100vw - 900px) * 0.3)), 30px)',
      [theme.breakpoints.down('md')]: {
        bottom: '-30px',
      },
    },
    '& div.swiper-wrapper': {
      overflow: noImages ? 'hidden' : 'auto hidden',
      ...scrollBar,
    },
  }),
);

export default classes;
