import { Box } from '@mui/material';
import { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import React, { useCallback, useMemo } from 'react';
import { BannerLocationType } from '~/data/consts';
import classes, {
  backgroundCustomStyle,
  StyledSwiper,
  StyledSwiperSlide,
} from './useStyles';
import { useIsMobile } from '~/hooks/useIsMobile';
import { FONTS_SIZE_LABELS } from '~/data/ui/fontSizes.data';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import useFreesbeTextToLogo from '~/hooks/useFreesbeTextToLogo';
import MarketingBannerCard from '../MarketingBannerCard';
import {
  IBannerCard,
  IBannerCarousel,
  IMarketingBannerCarousel,
} from '~/data/types/MarketingBannerCarousels/MarketingBannerCarousels.types';

interface StaticBannersCarouselProps {
  bannerCarousels: IBannerCarousel | null;
  location: BannerLocationType;
  title?: string;
  customContainerStyles?: React.CSSProperties;
}

const DEFAULT_CAROUSEL_LIMIT = 2;
const DEFAULT_SLIDES_LIMIT = 4;

const StaticBannersCarousel = ({
  bannerCarousels,
  location,
  title,
  customContainerStyles,
}: StaticBannersCarouselProps) => {
  const events = useEvents();
  const isMobile = useIsMobile();
  const TitleComponent = useFreesbeTextToLogo(title || '');
  const styles = classes({ isSingleBanner: false });

  const fontSizes = useMemo(
    () => ({
      title: isMobile ? FONTS_SIZE_LABELS.X_LARGE : FONTS_SIZE_LABELS.XL_TITLE,
      description: isMobile
        ? FONTS_SIZE_LABELS.X_LARGE
        : FONTS_SIZE_LABELS.XL_TITLE,
    }),
    [isMobile],
  );

  const {
    maxNumberOfBannerCarousels,
    marketingBannerCarousels,
    campaignMarketingBannerCarousels,
  } = bannerCarousels || {};

  const limitedCarousels = useMemo(() => {
    const limit = maxNumberOfBannerCarousels || DEFAULT_CAROUSEL_LIMIT;
    const carousels =
      campaignMarketingBannerCarousels ?? marketingBannerCarousels;

    return carousels?.slice(0, limit) ?? [];
  }, [
    marketingBannerCarousels,
    maxNumberOfBannerCarousels,
    campaignMarketingBannerCarousels,
  ]);

  const handleEventOnClick = useCallback(
    (banner: IBannerCard) => {
      events.sendEvent(
        {
          action: EActionEvent.BANNER_CLICKED,
          category: ECategoryEvent.BANNER,
        },
        {
          bannerTitle: banner.info.title,
          businessLine: banner.lineOfBusiness,
        },
      );
    },
    [events],
  );

  if (
    !bannerCarousels ||
    (bannerCarousels.marketingBannerCarousels?.length === 0 &&
      bannerCarousels.campaignMarketingBannerCarousels?.length === 0)
  )
    return null;

  return (
    <Box
      sx={{ ...styles.root, ...customContainerStyles, paddingBottom: '40px' }}
    >
      {title && (
        <TitleComponent sx={styles.title} fontFamily="fontWeightBold" />
      )}
      {limitedCarousels.map(
        (bannerCarousel: IMarketingBannerCarousel, index) => {
          const limitedBannerCards = bannerCarousel.bannerCards.slice(
            0,
            bannerCarousel.limit || DEFAULT_SLIDES_LIMIT,
          );

          if (bannerCarousel.bannerCards.length === 0) return null;

          return (
            <StyledSwiper
              aria-label="באנרים"
              aria-roledescription="carousel"
              grabCursor
              navigation
              cssMode
              pagination
              noImages={false}
              isNavigationDots={true}
              modules={[Pagination, Navigation, Autoplay]}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              key={`bannerCarousel.id-${index}`}
            >
              {limitedBannerCards.map((banner, index) => (
                <StyledSwiperSlide
                  key={`banner-${index}`}
                  role="group"
                  aria-roledescription="slide"
                  aria-label={`${bannerCarousel.bannerCards.length} מתוך ${
                    index + 1
                  }`}
                >
                  <MarketingBannerCard
                    banner={banner}
                    type="banner"
                    location={location}
                    showButton={!banner.preDesignedBanner}
                    hovered={banner.preDesignedBanner}
                    defaultTitleSize={fontSizes.title}
                    defaultDescriptionSize={fontSizes.description}
                    boldTitle
                    boldDescription
                    role={banner.preDesignedBanner ? 'link' : 'article'}
                    tabbable={banner.preDesignedBanner}
                    hideInfo={banner.preDesignedBanner}
                    customEventFunction={() => handleEventOnClick(banner)}
                    alt={banner.info.title || undefined}
                    customStyle={backgroundCustomStyle}
                  />
                </StyledSwiperSlide>
              ))}
            </StyledSwiper>
          );
        },
      )}
    </Box>
  );
};

export default StaticBannersCarousel;
