import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { CSSProperties } from 'react';
import { JustifyContentType } from '~/data/ui/system';
import theme from '~/theme';
import { BannerType } from '~/data/types/bannerCard/bannerCard.types';
import ReactMarkdown from 'react-markdown';
import { scrollBar } from '~/styles/useStyleGlobal';
import Image from 'next/image';
import { COLORS } from '~/data/ui/colors';
const TEXT_LINE_HEIGHT = 1.1;

export const RootBox = styled(Box)<{
  hovered: string;
  showtooltip: string;
  background?: string;
  type?: 'card' | 'banner';
}>`
  ${(props) => ({
    display: 'flex',
    borderRadius: '8px',
    flexDirection: 'column',
    backgroundImage:
      props.type === 'banner' ? `url(${props.background})` : 'none',

    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      ' & .banner-card-image': {
        backgroundColor:
          props.showtooltip === 'true'
            ? 'rgba(0, 0, 0, 0.35)'
            : 'rgba(0, 0, 0, 0)',
        borderRadius: '8px',
        transition: 'all 0.3s ease-in-out',
      },

      backgroundImage:
        props.showtooltip === 'true' &&
        props.type === 'banner' &&
        `linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url(${props.background})`,

      '&:hover': {
        backgroundImage:
          props.hovered === 'true' &&
          props.type === 'banner' &&
          `linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url(${props.background})`,
        cursor: props.hovered === 'true' && 'pointer',

        ' & .banner-card-image': {
          backgroundColor: ' rgba(0, 0, 0, 0.35)',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      backgroundImage:
        props.showtooltip === 'true' &&
        `linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url(${props.background})`,
    },
  })}
`;

export const StyledImage = styled(Image)({
  position: 'relative',
  zIndex: '-5',
  borderRadius: '8px',
  transition: 'transform 0.3s ease-in-out',

  '&:hover': {
    transform: 'scale(1.05)',
  },
});

export const ContentBox = styled(Box)<{ type: BannerType }>`
  ${(props) => ({
    direction: 'rtl',
    display: 'flex',
    flexDirection: 'column',
    padding: props.type === 'card' ? '18px' : '30px',
    backgroundColor: props.type === 'banner' ? 'rgba(0,0,0,.4)' : 'transparent',
    borderRadius: '8px',
    height: '100%',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
    },
  })}
`;

export const InnerContentBox = styled(Box)<{
  justifyContent: JustifyContentType;
}>`
  ${(props) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: props.justifyContent,
    gap: '16px',
  })}
`;

export const TypographyStyled = styled(Typography)<{
  color: string;
  fontSize: string;
}>`
  ${(props) => ({
    fontSize: props.fontSize,
    color: props.color,
  })}
`;

const classes: { [key: string]: CSSProperties } = {
  rootBanner: {
    width: '100%',
    aspectRatio: '4/3',
    [theme.breakpoints.up('md')]: {
      maxWidth: '992px',
      height: '342px',
      aspectRatio: '16/9',
    },
  },
  rootCard: {
    width: '249px !important',
    height: '332px',
    [theme.breakpoints.up('md')]: {
      width: '279px !important',
      height: '372px',
    },
  },
  flexItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  vertical: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  innerContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
  },
  regularButton: {
    gap: '8px',
    [theme.breakpoints.down('md')]: {
      height: '35px',
    },
  },
  circleButton: {
    minWidth: 'unset',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
  },
  boldText: {
    fontFamily: 'EricaSansFOT,GillSans',
    fontWeight: '800',
  },
};

export const textContainerAlignmentStyle: { [key: string]: CSSProperties } = {
  topRight: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  bottomRight: {
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  topLeft: {
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  bottomLeft: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const textAlignmentStyle: { [key: string]: CSSProperties } = {
  topRight: {
    textAlign: 'right',
    lineHeight: TEXT_LINE_HEIGHT,
  },
  bottomRight: {
    textAlign: 'right',
    lineHeight: TEXT_LINE_HEIGHT,
  },
  topLeft: {
    textAlign: 'left',
    lineHeight: TEXT_LINE_HEIGHT,
  },
  bottomLeft: {
    textAlign: 'left',
    lineHeight: TEXT_LINE_HEIGHT,
  },
  center: {
    textAlign: 'center',
    lineHeight: TEXT_LINE_HEIGHT,
  },
};

export const iconStyle = styled('svg')(() => ({
  width: '18px',
  height: '18px',
}));

export const tooltip = () =>
  ({
    display: 'flex',
    margin: '0 auto',
    width: '85%',
    zIndex: '99',
    position: 'absolute',
    top: '1rem',
    left: '0',
    right: '0',
    [theme.breakpoints.down('md')]: {
      top: '0.7rem',
      width: '93%',
    },

    '&:focus': {
      outlineOffset: '0px',
      border: 'none',

      '& > svg': {
        outlineOffset: '2px',
        border: '1px dashed white',
      },
    },

    '& > svg': {
      marginLeft: '-9px',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0px',
      },
    },
    '& span': {
      ...scrollBar,
      padding: '12px 12px',
      backgroundColor: COLORS.white.main,
      position: 'absolute',
      left: '0',
      right: '0',
      margin: '0 auto',
      borderRadius: '6px',
      borderTop: `5px solid ${COLORS.primary.main}`,
      overflowY: 'auto',
      overflowX: 'hidden',
      height: 'max-content',
      maxHeight: '280px',
      top: '2rem',
      [theme.breakpoints.down('md')]: {
        maxHeight: '250px',
      },
    },
    '&:hover span': {
      visibility: 'visible',
    },
  }) as const;

export const StyledMarkDown = styled(ReactMarkdown)(() => ({
  display: 'flex',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '12px',
  marginBottom: '32px',
  fontFamily: 'EricaSansFOT',
  margin: '0px',
  marginTop: '5px',
  ' & strong': {
    fontFamily: 'EricaSansFOT,GillSans',
    fontWeight: '800',
  },
  ' & p,  & h3': {
    marginBottom: '7px',
  },
  ' & h2': {
    margin: '0',
  },
  ' & ul': {
    direction: 'rtl',
    marginRight: '10px',
  },
}));

export const exitIconContainer = {
  float: 'left',
  '& > svg': {
    width: '20px',
    height: '20px',
  },
};

export const bannerCardOutline = {
  '&:focus-visible': {
    outlineOffset: '-2px',
  },
};
export default classes;
