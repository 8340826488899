import { Box } from '@mui/material';
import {
  CSSProperties,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
  forwardRef,
} from 'react';
import { useRouter } from 'next/router';
import ArrowLeftNoLineIcon from '~/assets/icons/jsx/ArrowLeftNoLine';
import ArrowLeftIcon from '~/assets/icons/jsx/ArrowLeft';
import { BannerType } from '~/data/types/bannerCard/bannerCard.types';
import {
  FONT_SIZES,
  FontSizesType,
  MOBILE_FONT_SIZE_MAP,
} from '~/data/ui/fontSizes.data';
import { BANNER_TYPE, BannerLocationType } from '~/data/consts';
import { TEXT_ALIGNMENT, TEXT_COLORS } from '~/data/ui/typograpy.constants';
import { BUTTON_SIZES, BUTTON_TYPES, KEYBOARD_TYPES } from '~/data/constants';
import { useContactUsPopup } from '~/hooks/useContactUsPopup';
import Button from '../Button';
import { JustifyContentType } from '~/data/ui/system';
import classes, {
  StyledImage,
  RootBox,
  ContentBox,
  textContainerAlignmentStyle,
  textAlignmentStyle,
  TypographyStyled,
  iconStyle,
  tooltip,
  StyledMarkDown,
  exitIconContainer,
  bannerCardOutline,
} from './useStyles';
import { EContactUsFlow } from '~/data/ContactUsPopup/enums';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import { useIsMobile } from '~/hooks/useIsMobile';
import TooltipInfoIcon from '~/assets/icons/jsx/tooltipInfoIcon';
import { ExitIcon } from '~/assets/icons/jsx/ExitIcon';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux-toolkit/store/store';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import { setGalleryToolTip } from '~/redux-toolkit/slices/tooltipSlice';
import { useImageKitS3 } from '~/hooks/useImageKitS3';
import { IBannerCard } from '~/data/types/MarketingBannerCarousels/MarketingBannerCarousels.types';
interface MarketingBannerCardProps {
  banner: IBannerCard;
  customStyle?: CSSProperties;
  type: BannerType;
  vertical?: boolean;
  showButton?: boolean;
  justifyContent?: JustifyContentType;
  customButton?: JSX.Element;
  hovered?: boolean;
  location: BannerLocationType;
  customEventFunction?: () => void;
  defaultTitleSize?: FontSizesType;
  defaultDescriptionSize?: FontSizesType;
  boldTitle?: boolean;
  boldDescription?: boolean;
  role?: string;
  tabbable?: boolean;
  hideInfo?: boolean;
  sliderContent?: {
    index: number;
    activeSlideIndex: number;
    nextSlideIndex: number;
    prevSlideIndex: number;
    length: number;
  };
  alt?: string;
}

const MarketingBannerCard = forwardRef<Slider, MarketingBannerCardProps>(
  (
    {
      banner,
      customStyle,
      type,
      vertical = false,
      showButton = false,
      justifyContent = 'flex-start',
      customButton,
      hovered = false,
      location,
      defaultTitleSize,
      defaultDescriptionSize,
      customEventFunction,
      boldTitle,
      boldDescription,
      role,
      tabbable = false,
      hideInfo = false,
      sliderContent,
      alt,
    },
    ref,
  ) => {
    const events = useEvents();
    const isMobile = useIsMobile();
    const dispatch = useAppDispatch();
    const [isHovered, setIsHovered] = useState(isMobile);
    const { galleryTooltip } = useSelector((state: RootState) => state.tooltip);
    const { openContactUsModal } = useContactUsPopup();
    const [showTooltip, setShowTooltip] = useState(false);
    const router = useRouter();
    const cardRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setIsHovered(isMobile);
    }, [isMobile]);

    const styleByType = useMemo(() => {
      switch (type) {
        case 'banner':
          return classes.rootBanner;
        case 'card':
          return classes.rootCard;
        default:
          return classes.rootBanner;
      }
    }, [type]);

    const alignment = useMemo(
      () => banner.info.textAlignment || TEXT_ALIGNMENT.BOTTOM_RIGHT,
      [banner.info.textAlignment],
    );

    const alignmentStyle = useMemo(
      () => textContainerAlignmentStyle[alignment],
      [alignment],
    );
    const typographyAlignmentStyle = useMemo(
      () => textAlignmentStyle[alignment],
      [alignment],
    );
    const color = useMemo(
      () =>
        banner.info.textColor
          ? TEXT_COLORS[banner.info.textColor as keyof typeof TEXT_COLORS]
          : TEXT_COLORS.light,
      [banner.info.textColor],
    );

    const cardFlot = useMemo(() => {
      if (!alignmentStyle) return {};

      return {
        position: 'absolute',
        top: alignmentStyle.alignItems === 'flex-start' ? 0 : 'auto',
        bottom: alignmentStyle.alignItems === 'flex-end' ? 0 : 'auto',
        left: alignmentStyle.justifyContent === 'flex-start' ? 0 : 'auto',
        right: alignmentStyle.justifyContent === 'flex-end' ? 0 : 'auto',
      };
    }, [alignmentStyle]);

    const handleBtnOnClick = useCallback(() => {
      if (customEventFunction) {
        customEventFunction();
      }
      if (banner.bannerType === BANNER_TYPE.LINK && banner.bannerLink) {
        banner.openLinkInNewTab
          ? window.open(banner.bannerLink, '_blank')
          : router.push(banner.bannerLink);
      } else if (banner.bannerType === BANNER_TYPE.LEAD) {
        events.sendEvent(
          {
            action: EActionEvent.LEAD_LOAD,
            category: ECategoryEvent.LEAD,
          },
          {
            leadLocation: location,
          },
        );
        openContactUsModal({
          flow: EContactUsFlow.LEAVE_DETAILS,
          campaignNumber: banner.leadDetails?.campaignNumber,
          lineOfBusiness: banner.leadDetails?.lineOfBusiness,
          subMedia: banner.leadDetails?.subMedia,
          note: banner.leadDetails?.note ?? '',
          leadLocation: location,
        });
      }
    }, [
      customEventFunction,
      banner.bannerType,
      banner.bannerLink,
      banner.openLinkInNewTab,
      banner.leadDetails?.campaignNumber,
      banner.leadDetails?.lineOfBusiness,
      banner.leadDetails?.subMedia,
      banner.leadDetails?.note,
      events,
      location,
      openContactUsModal,
      router,
    ]);

    const props = useMemo(() => (role ? { role } : {}), [role]);

    const getFontSize = (
      sizeKey: string,
      isMobile: boolean,
      type: 'banner' | 'card',
    ): string => {
      if (type === 'card') return sizeKey;

      const defaultSize = 'm';
      const effectiveSizeKey = sizeKey ?? defaultSize;

      // If mobile, use the mapped size, else use the provided size
      const finalSizeKey = isMobile
        ? MOBILE_FONT_SIZE_MAP[effectiveSizeKey] || effectiveSizeKey
        : effectiveSizeKey;
      return FONT_SIZES[finalSizeKey] || FONT_SIZES[defaultSize];
    };

    // Title font size
    const titleFontSize = getFontSize(
      banner.info.titleSize ?? defaultTitleSize ?? 'l',
      isMobile,
      type,
    );

    // Description Font size
    const descriptionFontSize = getFontSize(
      banner.info.descriptionSize ?? defaultDescriptionSize ?? 'm',
      isMobile,
      type,
    );

    const handleSliderMovement = useCallback(() => {
      if (!sliderContent || !ref) {
        return;
      }
      const { activeSlideIndex, index } = sliderContent;
      if (
        ref &&
        typeof ref === 'object' &&
        ref.current &&
        activeSlideIndex !== index
      ) {
        const { length, nextSlideIndex } = sliderContent;
        if (
          nextSlideIndex !== 0 &&
          nextSlideIndex === index &&
          nextSlideIndex < activeSlideIndex
        ) {
          ref.current?.slickPrev();
          return;
        }
        if (ref.current && length - 1 === activeSlideIndex) {
          ref.current?.slickNext();
          return;
        }
        if (length - 1 === index && activeSlideIndex === 0) {
          ref.current?.slickPrev();
          return;
        }

        (index || 0) > (activeSlideIndex || 0)
          ? ref.current?.slickNext()
          : ref.current?.slickPrev();
      }
    }, [ref, sliderContent]);

    const handleTooltipToggle = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event?.stopPropagation();
        if (!sliderContent) {
          return;
        }
        const { index } = sliderContent;

        dispatch(setGalleryToolTip({ id: index, isOpen: !showTooltip }));
        handleSliderMovement();
      },
      [sliderContent, showTooltip, dispatch, handleSliderMovement],
    );

    const handleScroll = useCallback(() => {
      setShowTooltip(false);
    }, []);

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [handleScroll]);

    const handleKeyDown = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (event: any) => {
        if (
          event.key === KEYBOARD_TYPES.ENTER ||
          event.key === KEYBOARD_TYPES.SPACE
        ) {
          handleTooltipToggle(event);
        }
      },
      [handleTooltipToggle],
    );

    const handleClickOutside = useCallback((event: Event) => {
      if (
        cardRef.current &&
        !cardRef.current.contains(event.target as Node) &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setShowTooltip(false);
      }
    }, []);

    const isTooltipVisible = useMemo(() => {
      const isAdjacent =
        (sliderContent?.index === sliderContent?.nextSlideIndex && isHovered) ||
        (sliderContent?.index === sliderContent?.prevSlideIndex && isHovered) ||
        (sliderContent?.index === sliderContent?.activeSlideIndex &&
          showTooltip) ||
        (sliderContent?.index === sliderContent?.activeSlideIndex && isHovered);
      return !!(banner.tooltipContent && isAdjacent);
    }, [banner?.tooltipContent, sliderContent, isHovered, showTooltip]);

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('touchstart', handleClickOutside);
      };
    }, [handleClickOutside]);

    useEffect(() => {
      if (galleryTooltip?.id === sliderContent?.index) {
        setShowTooltip(galleryTooltip.isOpen);
      }
    }, [galleryTooltip, sliderContent]);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      if (isMobile) return;
      setIsHovered(false);
    };

    useEffect(() => {
      if (isMobile) {
        setIsHovered(true);
      }
    }, [isMobile]);

    const getImageKit = useImageKitS3;

    const loadPriority = useMemo(() => {
      return (
        sliderContent?.nextSlideIndex === 2 &&
        sliderContent?.prevSlideIndex === 0
      );
    }, [sliderContent?.nextSlideIndex, sliderContent?.prevSlideIndex]);

    const keyStr =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

    const triplet = (e1: number, e2: number, e3: number) =>
      keyStr.charAt(e1 >> 2) +
      keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
      keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
      keyStr.charAt(e3 & 63);

    const rgbDataURL = (r: number, g: number, b: number) =>
      `data:image/gif;base64,R0lGODlhAQABAPAA${
        triplet(0, r, g) + triplet(b, 255, 255)
      }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

    return (
      <RootBox
        ref={cardRef}
        {...(type === 'banner' && {
          background: getImageKit(
            (isMobile && banner.mobileImage.url) || banner.image.url,
          ),
        })}
        sx={{ ...styleByType, ...customStyle }}
        hovered={`${hovered}`}
        showtooltip={`${showTooltip}`}
        onClick={() => !showButton && handleBtnOnClick()}
        {...props}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        tabIndex={tabbable ? 0 : -1}
        type={type}
        {...(alt && { alt })}
      >
        {type === 'card' && (
          <Box className="banner-card-image banner-card">
            <StyledImage
              src={getImageKit(banner.image.url)}
              alt={banner.info.title}
              width={!isMobile ? 279 : 249}
              height={!isMobile ? 372 : 332}
              quality={75}
              priority={loadPriority}
              loading={loadPriority ? 'eager' : 'lazy'}
              placeholder={'blur'}
              blurDataURL={rgbDataURL(229, 246, 248)}
            />
          </Box>
        )}
        {isTooltipVisible && (
          <Box
            sx={tooltip()}
            tabIndex={0}
            onClick={(event) => handleTooltipToggle(event)}
            onKeyDown={(event) => handleKeyDown(event)}
            ref={tooltipRef}
          >
            <TooltipInfoIcon Component={iconStyle} fill="white" />
            {showTooltip && (
              <Box component="span" ref={tooltipRef}>
                <Box
                  sx={exitIconContainer}
                  onClick={() => {
                    setShowTooltip(false);
                  }}
                >
                  <ExitIcon />
                </Box>
                <StyledMarkDown>{banner.tooltipContent}</StyledMarkDown>
              </Box>
            )}
          </Box>
        )}
        {!hideInfo && (
          <ContentBox
            sx={{
              ...alignmentStyle,
              ...bannerCardOutline,
              ...(type === 'card' && cardFlot),
            }}
            type={type}
            role={!showButton ? 'button' : 'presentation'}
            className="banner-card"
          >
            <Box
              sx={{
                ...alignmentStyle,
                ...classes.innerContentContainer,
                justifyContent: justifyContent,
                ...(vertical && classes.vertical),
              }}
            >
              <Box sx={{ ...classes.flexItem, ...alignmentStyle }}>
                {/* title */}
                {banner.info.title && (
                  <TypographyStyled
                    color={color}
                    fontSize={titleFontSize}
                    sx={{
                      ...typographyAlignmentStyle,
                      ...(boldTitle && classes.boldText),
                    }}
                  >
                    {banner.info.title}
                  </TypographyStyled>
                )}
                {/* description */}
                {banner.info.description && (
                  <TypographyStyled
                    color={color}
                    fontSize={descriptionFontSize}
                    sx={{
                      ...typographyAlignmentStyle,
                      ...(boldDescription && classes.boldText),
                    }}
                  >
                    {banner.info.description}
                  </TypographyStyled>
                )}
              </Box>
              {!customButton &&
                showButton &&
                (banner.buttonTitle ? (
                  <Button
                    label={banner.buttonTitle}
                    size={BUTTON_SIZES.FIT}
                    type={BUTTON_TYPES.MAIN_YELLOW}
                    icon={<ArrowLeftIcon />}
                    isIconOnRight
                    customStyle={classes.regularButton}
                    onClickFunc={handleBtnOnClick}
                    ariaLabel={banner.info.title}
                  />
                ) : (
                  <Button
                    size={BUTTON_SIZES.FIT}
                    type={BUTTON_TYPES.MAIN_YELLOW}
                    label=""
                    icon={<ArrowLeftNoLineIcon />}
                    customStyle={classes.circleButton}
                    onClickFunc={handleBtnOnClick}
                    ariaLabel={banner.info.title}
                  />
                ))}
              {customButton && customButton}
            </Box>
          </ContentBox>
        )}
      </RootBox>
    );
  },
);

export default MarketingBannerCard;
