import { COLORS } from "./colors";

export const TEXT_ALIGNMENT = {
    TOP_RIGHT: 'topRight',
    TOP_LEFT: 'topLeft',
    CENTER: 'center',
    BOTTOM_RIGHT: 'bottomRight',
    BOTTOM_LEFT: 'bottomLeft',
} as const;

export const TEXT_COLOR_LABELS = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
    DANGER: 'danger',
    WARNING: 'warning',
    DARK: 'dark',
    LIGHT: 'light',
    INFO: 'info',
} as const;


export const TEXT_COLORS = {
    primary: COLORS.primary.main,
    secondary: COLORS.yellow.main,
    success: COLORS.green.main,
    danger: COLORS.red.error,
    warning: COLORS.orange.main,
    dark: COLORS.black.main,
    light: COLORS.white.main,
    info: COLORS.gray.dark,
}

type TextAlignmentKeys = keyof typeof TEXT_ALIGNMENT;
export type TextAlignmentType = (typeof TEXT_ALIGNMENT)[TextAlignmentKeys];


type TextColorKeys = keyof typeof TEXT_COLOR_LABELS;
export type TextColorType = (typeof TEXT_COLOR_LABELS)[TextColorKeys];