import { SvgIcon } from '@mui/material';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ExitIcon = React.forwardRef((props, ref: any) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 32 32" data-fill-none>
    <path
      d="M9.33358 22.6783L22.6668 9.34509"
      stroke="black"
      strokeWidth="1.0625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.3275 9.33908L22.6734 22.685"
      stroke="black"
      strokeWidth="1.0625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
));
