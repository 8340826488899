import { Typography, TypographyProps } from '@mui/material';
import { useMemo } from 'react';
import BlackFreesbeLogoIcon from '~/assets/images/jsx/blackFreesbeLogo';

const useFreesbeTextToLogo = (text: string) => {
  const bannerTitle = useMemo(() => {
    if (text) {
      const parts = text.split(new RegExp('(freesbe)', 'gi'));
      return parts.map((part, index) =>
        part.toLowerCase() === 'freesbe' ? (
          <BlackFreesbeLogoIcon key={index} />
        ) : (
          part
        ),
      );
    }
    return text;
  }, [text]);

  const Component = (props: TypographyProps) => {
    return (
      <Typography {...props}>
        {bannerTitle}
        {props.children}
      </Typography>
    );
  };

  return Component;
};

export default useFreesbeTextToLogo;
